import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../App.css";

const Testimonial = () => {
  return (
    <div className="container testimonialChangeSec">
      <div className="col text-center">
        <h1>
          <span>We Have Happy Customers, See Responses</span>
        </h1>
        <p style={{ color: "#FC5952", fontWeight: "600", fontSize: "17px" }}>
          Our Testimonials
        </p>
      </div>
      <Carousel
        showIndicators={false}
        interval={3000}
        className="custom-carousel customCarouselDesktop"
      >
        {/* <div className="customChangeMyReq">
          <div className="challengeRowChange">
            <div className="imageSlider">
              <img
                className="sliderCorImage"
                src="assets/img/slider_images/maheshjain.png"
                alt="maheshjain"
              />
            </div>
            <div className="textSlider">
              <div className="myOwnCardChange marginLeftChange">
                <h5 className="card-title">Mahesh Jain</h5>
                <p className="shopOwnerChange">Shop Owner</p>
                <p className="card-text">
                  very quick service, app usage is very simple. material
                  supplied is through known distributors
                </p>
                <div className="reviewSectionBottom">
                  <div className="importData">
                    <img src="assets/img/icon/shop_icon.png" alt="icon" />
                    <h6>Nakoda Electrical</h6>
                  </div>
                  <div className="importData">
                    <img src="assets/img/icon/location_mark.png" alt="icon" />
                    <p className="mer-address">
                      Mohit Patil Rd, Mankhurd, Mumbai, Maharashtra 400043
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="challengeRowChange">
            <div className="textSlider">
              <div className="myOwnCardChange marginRightChange">
                <h5 className="card-title">Sandeep Pinnamaneni</h5>
                <p className="shopOwnerChange">Shop Owner</p>
                <p className="card-text">
                  very quick service, app usage is very simple. material
                  supplied is through known distributors
                </p>
                <div className="reviewSectionBottom">
                  <div className="importData">
                    <img src="assets/img/icon/shop_icon.png" alt="icon" />
                    <h6>AlignElevators</h6>
                  </div>
                  <div className="importData">
                    <img src="assets/img/icon/location_mark.png" alt="icon" />
                    <p className="mer-address">
                      Mohit Patil Rd, Mankhurd, Mumbai, Maharashtra 400043
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="imageSlider">
              <img
                className="sliderCorImage"
                src="assets/img/slider_images/sandeep.png"
                alt="maheshjain"
              />
            </div>
          </div>
        </div> */}
        {/* ----------- 2 ----------- */}
        <div className="customChangeMyReq">
          <div className="challengeRowChange">
            <div className="imageSlider">
              <img
                className="sliderCorImage"
                src="assets/img/slider_images/vilassethe.png"
                alt="vilassethe"
              />
            </div>
            <div className="textSlider">
              <div className="myOwnCardChange marginLeftChange">
                <h5 className="card-title">Vilas Shete</h5>
                <p className="shopOwnerChange">Shop Owner</p>
                <p className="card-text">
                  very quick service, app usage is very simple. material
                  supplied is through known distributors
                </p>
                <div className="reviewSectionBottom">
                  <div className="importData">
                    <img src="assets/img/icon/shop_icon.png" alt="icon" />
                    <h6>Laxmi Electrical</h6>
                  </div>
                  <div className="importData">
                    <img src="assets/img/icon/location_mark.png" alt="icon" />
                    <p className="mer-address">
                      Shop 5,Nilkhant complex,Shree colony, Maharashtra, 402302.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="challengeRowChange">
            <div className="textSlider">
              <div className="myOwnCardChange marginRightChange">
                <h5 className="card-title">Savita Sawant</h5>
                <p className="shopOwnerChange">Shop Owner</p>
                <p className="card-text">
                  very quick service, app usage is very simple. material
                  supplied is through known distributors
                </p>
                <div className="reviewSectionBottom">
                  <div className="importData">
                    <img src="assets/img/icon/shop_icon.png" alt="icon" />
                    <h6>DeepShree Electronics</h6>
                  </div>
                  <div className="importData">
                    <img src="assets/img/icon/location_mark.png" alt="icon" />
                    <p className="mer-address">
                    Shop no. 3, Baneshvar paradise, A/p Nasrapur Tal bhor pune, Maharashtra, 412213.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="imageSlider">
              <img
                className="sliderCorImage"
                src="assets/img/slider_images/vilasShop.png"
                alt="vilasShop"
              />
            </div>
          </div>
        </div>
        {/* 1 */}
        <div className="customChangeMyReq">
          <div className="challengeRowChange">
            <div className="imageSlider">
              <img
                className="sliderCorImage"
                src="assets/img/slider_images/maheshjain.png"
                alt="vilassethe"
              />
            </div>
            <div className="textSlider">
              <div className="myOwnCardChange marginLeftChange">
                <h5 className="card-title">Mahesh Jain</h5>
                <p className="shopOwnerChange">Shop Owner</p>
                <p className="card-text">
                  very quick service, app usage is very simple. material
                  supplied is through known distributors
                </p>
                <div className="reviewSectionBottom">
                  <div className="importData">
                    <img src="assets/img/icon/shop_icon.png" alt="icon" />
                    <h6>Laxmi Electrical</h6>
                  </div>
                  <div className="importData">
                    <img src="assets/img/icon/location_mark.png" alt="icon" />
                    <p className="mer-address">
                      Shop 5,Nilkhant complex,Shree colony,MAHARASHTRA,402302.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="challengeRowChange">
            <div className="textSlider">
              <div className="myOwnCardChange marginRightChange">
                <h5 className="card-title">Sandeep Pinnamaneni</h5>
                <p className="shopOwnerChange">Shop Owner</p>
                <p className="card-text">
                  very quick service, app usage is very simple. material
                  supplied is through known distributors
                </p>
                <div className="reviewSectionBottom">
                  <div className="importData">
                    <img src="assets/img/icon/shop_icon.png" alt="icon" />
                    <h6>ALIGNELEVATORS</h6>
                  </div>
                  <div className="importData">
                    <img src="assets/img/icon/location_mark.png" alt="icon" />
                    <p className="mer-address">
                    Flat no 406 ,block 1,Radha madhav nilayam,Pragathi enclave,Medchal Malkajgiri, TELANGANA,500049.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="imageSlider">
              <img
                className="sliderCorImage"
                src="assets/img/slider_images/sandeep.png"
                alt="vilasShop"
              />
            </div>
          </div>
        </div>
        {/* 1 */}
        {/* ----------- 3 ----------- */}
        <div className="customChangeMyReq">
          <div className="challengeRowChange">
            <div className="imageSlider">
              <img
                className="sliderCorImage"
                src="assets/img/slider_images/madanlal.png"
                alt="madanlal"
              />
            </div>
            <div className="textSlider">
              <div className="myOwnCardChange marginLeftChange">
                <h5 className="card-title">Madanlal Chaudhary</h5>
                <p className="shopOwnerChange">Shop Owner</p>
                <p className="card-text">
                  very quick service, app usage is very simple. material
                  supplied is through known distributors
                </p>
                <div className="reviewSectionBottom">
                  <div className="importData">
                    <img src="assets/img/icon/shop_icon.png" alt="icon" />
                    <h6>Shiv Electrical</h6>
                  </div>
                  <div className="importData">
                    <img src="assets/img/icon/location_mark.png" alt="icon" />
                    <p className="mer-address">
                      Capital squre,Nighoje chakan,Opp mahindra comapny
                      nighoje,MAHARASHTRA,411062
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="challengeRowChange">
            <div className="textSlider">
              <div className="myOwnCardChange marginRightChange">
                <h5 className="card-title">Madan Jain</h5>
                <p className="shopOwnerChange">Shop Owner</p>
                <p className="card-text">
                  very quick service, app usage is very simple. material
                  supplied is through known distributors
                </p>
                <div className="reviewSectionBottom">
                  <div className="importData">
                    <img src="assets/img/icon/shop_icon.png" alt="icon" />
                    <h6>Suresh hardware and general Stores</h6>
                  </div>
                  <div className="importData">
                    <img src="assets/img/icon/location_mark.png" alt="icon" />
                    <p className="mer-address">
                    Shop no 1&2,Kadvi Bai chawl station road,Govandi east,Mumbai Suburban,MAHARASHTRA,400088.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="imageSlider">
              <img
                className="sliderCorImage"
                src="assets/img/slider_images/madanlalShop.png"
                alt="madanlal"
              />
            </div>
          </div>
        </div>
      </Carousel>

      {/* ---------------------------- For Mobile --------------------------------------- */}
      <Carousel
        showIndicators={false}
        interval={3000}
        className="custom-carousel customCarouselMobile"
      >
        <div className="customChangeMyReq">
          <div className="challengeRowChange">
            <div className="imageSlider">
              <img
                className="sliderCorImage"
                src="assets/img/slider_images/maheshjain.png"
                alt="maheshjain"
              />
            </div>
            <div className="textSlider">
              <div className="myOwnCardChange marginLeftChange">
                <h5 className="card-title">Mahesh Jain</h5>
                <p className="shopOwnerChange">Shop Owner</p>
                <p className="card-text">
                  very quick service, app usage is very simple. material
                  supplied is through known distributors
                </p>
                <div className="reviewSectionBottom">
                  <div className="importData">
                    <img src="assets/img/icon/shop_icon.png" alt="icon" />
                    <h6>Nakoda Electrical</h6>
                  </div>
                  <div className="importData">
                    <img src="assets/img/icon/location_mark.png" alt="icon" />
                    <p>Mohit Patil Rd, Mankhurd, Mumbai, Maharashtra 400043</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----------- 2 ----------- */}
        <div className="customChangeMyReq">
          <div className="challengeRowChange">
            <div className="imageSlider">
              <img
                className="sliderCorImage"
                src="assets/img/slider_images/vilassethe.png"
                alt="maheshjain"
              />
            </div>
            <div className="textSlider">
              <div className="myOwnCardChange marginLeftChange">
                <h5 className="card-title">VILAS SHETE</h5>
                <p className="shopOwnerChange">Shop Owner</p>
                <p className="card-text">
                  very quick service, app usage is very simple. material
                  supplied is through known distributors
                </p>
                <div className="reviewSectionBottom">
                  <div className="importData">
                    <img src="assets/img/icon/shop_icon.png" alt="icon" />
                    <h6>Laxmi Electrical</h6>
                  </div>
                  <div className="importData">
                    <img src="assets/img/icon/location_mark.png" alt="icon" />
                    <p>
                      Shop 5,Nilkhant complex,Shree colony,MAHARASHTRA,402302
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----------- 3 ----------- */}
        <div className="customChangeMyReq">
          <div className="challengeRowChange">
            <div className="imageSlider">
              <img
                className="sliderCorImage"
                src="assets/img/slider_images/madanlal.png"
                alt="maheshjain"
              />
            </div>
            <div className="textSlider">
              <div className="myOwnCardChange marginLeftChange">
                <h5 className="card-title">Madanlal Chaudhary</h5>
                <p className="shopOwnerChange">Shop Owner</p>
                <p className="card-text">
                  Forfeited you engrossed but guy sometimes explained. Another
                  as studied it to evident
                </p>
                <div className="reviewSectionBottom">
                  <div className="importData">
                    <img src="assets/img/icon/shop_icon.png" alt="icon" />
                    <h6>Shiv Electrical</h6>
                  </div>
                  <div className="importData">
                    <img src="assets/img/icon/location_mark.png" alt="icon" />
                    <p>
                      Capital squre,Nighoje chakan,Opp mahindra comapny
                      nighoje,MAHARASHTRA,411062
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Testimonial;
