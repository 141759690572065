import React from "react";

const DemoPage = () => {
  return (
    <div className="container">
      <div className="seconddemosec">
        <div className="text-center mb-4 mt-4">
          <h1 className="headersecondsec">
            <span>Buy Products On Credit Via Distributor Network</span>
          </h1>
          <p className="sub_head_para">
            Enhance Your Sales And Inventory With Daqi
          </p>
        </div>
        <div className="vertical-line-section">
          <div className="vertical-line"></div>
          <div className="circle-container">
            <div className="circle">
              <img src="assets/img/cart_image.png" alt="" />
              <div className="left-text1">
                <p className="">
                  Material dispatched / invoice generated <br />{" "}
                  <span className="p-paragraph">
                    sales fulfilment (material delivery and confirmation) to be
                    managed by partner distributor/manufacturer
                  </span>
                  <br />
                </p>
              </div>
            </div>
            <div className="circle second_circledemo">
              <img src="assets/img/upload_image.png" alt="" />
              <div className="right-text1">
                <p className="">
                  Uploads Invoice for Approval <br />{" "}
                  <span className="p-paragraph">
                    Merchant or distributor to initiate payment via uploading
                    invoice
                  </span>
                  <br />
                </p>
              </div>
            </div>
            <div className="circle third_circledemo">
              <img src="assets/img/trade_image.png" alt="" />
              <div className="left-text2">
                <p className="">
                  Invoice approval and <br />
                  trade confirmation <br />{" "}
                  <span className="p-paragraph">
                    Merchant will payback invoice <br />
                    amount after 30 days
                  </span>
                  <br />
                </p>
              </div>
            </div>
            <div className="circle fourth_circle_demo">
              <img src="assets/img/payment_image.png" alt="" />
              <div className="right-text2">
                <p className="">
                  Payment of Approved <br />{" "}
                  <span className="p-paragraph">
                    Merchant or distributor initiate payment via uploading
                    invoice
                  </span>
                  <br />
                </p>
              </div>
            </div>
            <div className="circle fifth_circle_demo">
              <img src="assets/img/repay_image.png" alt="" />
              <div className="left-text3">
                <p className="">
                  Repayment after 30 <br />
                  days
                  <br />{" "}
                  <span className="p-paragraph">
                    Merchant will payback <br />
                    invoice amount after 30 days
                  </span>
                  <br />
                </p>
              </div>
            </div>
          </div>
          <a
            href="https://play.google.com/store/apps/details?id=com.daqi.in"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="bottom-button"
              src="assets/img/credit_page/googlePlay.png"
              alt="Get it on Google Play"
            />
          </a>
        </div>
        <img
          className="second_diagonal"
          src="assets/img/credit_page/second_diagonal.png"
          alt="Get it on Google Play"
        />
        <img
          className="credit_page_logo"
          src="assets/img/credit_page/credit_page_logo.png"
          alt=""
        />
        <img
          className="rope_image"
          src="assets/img/credit_page/rope_image.png"
          alt="sell_page"
        />
      </div>
      {/* For Mobile version */}
      <section className="column-section">
        <div className="text-center headersectiomob mt-4">
          <h1 className="headersecondsec">
            <span>Buy Products On Credit Via Distributor Network</span>
          </h1>
          <p className="sub_head_para">
            Enhance Your Sales And Inventory With Daqi
          </p>
        </div>
        <div className="column">
          <div className="circle-mobile circle-mobile1">
            <img src="assets/img/cart_image.png" alt="" />
          </div>
          <p className="first-para">
            Material dispatched / invoice generated <br />{" "}
            <span className="p-paragraph">
              sales fulfilment (material delivery and confirmation) to be
              managed by partner distributor/manufacturer
            </span>
            <br />
          </p>
        </div>
        <div className="column">
          <div className="circle-mobile second_circledemo">
            <img src="assets/img/upload_image.png" alt="" />
          </div>
          <p className="first-para">
            Uploads Invoice for Approval <br />{" "}
            <span className="p-paragraph">
              Merchant or distributor to initiate payment via uploading invoice
            </span>
            <br />
          </p>
        </div>
        <div className="column">
          <div className="circle-mobile third_circledemo">
            <img src="assets/img/trade_image.png" alt="" />
          </div>
          <p className="first-para">
            Invoice approval and trade confirmation <br />{" "}
            <span className="p-paragraph">
              Merchant will payback invoice amount after 30 days
            </span>
            <br />
          </p>
        </div>
        <div className="column">
          <div className="circle-mobile fourth_circle_demo">
            <img src="assets/img/payment_image.png" alt="" />
          </div>
          <p className="first-para">
            Payment of Approved <br />{" "}
            <span className="p-paragraph">
              Merchant or distributor initiate payment via uploading invoice
            </span>
            <br />
          </p>
        </div>
        <div className="column">
          <div className="circle-mobile fifth_circle_demo">
            <img src="assets/img/repay_image.png" alt="" />
          </div>
          <p className="first-para">
            Repayment after 30 days <br />{" "}
            <span className="p-paragraph">
              Merchant will payback invoice amount after 30 days
            </span>
            <br />
          </p>
        </div>
        <a
          href="https://play.google.com/store/apps/details?id=com.daqi.in"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="centered-button"
            src="assets/img/credit_page/googlePlay.png"
            alt="Get it on Google Play"
          />
        </a>
      </section>
    </div>
  );
};

export default DemoPage;
